<template>
    <div>
        <!-- 정보입력 -->
        <div class="join-row-wrap">
            <!-- 정보입련: 일반 -->
            <div class="join-row join-row--form">
                <div class="join-caption join-caption--center">
                    <h3 class="title">회원정보 필수입력</h3>
                </div>
                <div role="table" class="join-table">
                    <div role="rowgroup">
                        <div role="row">
                            <div role="columnheader">아이디(이메일)</div>
                            <div role="cell">
                                <!-- 이메일 폼 -->
                                <div class="form-email">
                                    <input class="input" type="text" name="email1" v-model="user.email1">
                                    <span class="text">@</span>
                                    <input class="input" type="text" name="email2" v-model="user.email2">
                                    <select v-model="user.email3" name="email3" class="select" @change="user.email2 = user.email3">
                                        <option value="">직접입력</option>
                                        <option value="naver.com">naver.com</option>
                                        <option value="daum.net">daum.net</option>
                                        <option value="google.com">google.com</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div role="row">
                            <div role="columnheader">비밀번호</div>
                            <div role="cell">
                                <input class="input" type="password" name="password" v-model="user.password" @input="input">
                                <small class="text-grey text-grey--right">(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)</small>
                            </div>
                        </div>
                        <div role="row">
                            <div role="columnheader">비밀번호 확인</div>
                            <div role="cell">
                                <input class="input" type="password" name="password2" v-model="user.passwordConfirm" @input="input">
                            </div>
                        </div>
                        <!-- 일반 -->
                        <div role="row" style="width:50%;">                            
                            <div role="columnheader">
                                <span>이름</span>
                            </div>
                            <div role="cell">
                                <input class="input" type="text" name="name" v-model="user.name" @input="input">
                            </div>
                        </div>
                        <div role="row" style="width:50%;">  
                            <div role="columnheader">
                                <span>작가활동명</span>
                            </div>
                            <div role="cell">
                                <input class="input" type="text" name="nickname" v-model="user.meta.activeName" @input="input">
                            </div>
                        </div>

                        <div role="row">
                            <div role="columnheader">국내/해외</div>
                            <div role="cell">
                                 <select v-model="user.type" name="type" class="select">
                                    <option value="ko">국내이용자</option>
                                    <option value="en">해외이용자</option>
                                </select>
                            </div>
                        </div>

                        <div role="row">
                            <div role="columnheader">연락처</div>
                            <div role="cell">
                                <!-- 연락처 폼 -->
                                <div v-if="user.type == 'ko'" class="form-tel">
                                    <input class="input" type="tel" v-model="user.phone1" name="phone1" @input="input" maxlength="4" readonly @click="certify">
                                    <span class="text"></span>
                                    <input class="input" type="tel" v-model="user.phone2" name="phone2" @input="input" maxlength="4" readonly @click="certify">
                                    <span class="text"></span>
                                    <input class="input" type="tel" v-model="user.phone3" name="phone3" @input="input" maxlength="4" readonly @click="certify">
                                </div>

                                <div v-else class="form-tel-en">
                                    <span class="text">+</span>
                                    <input class="input" type="text" v-model="user.phone0" name="phone0" @input="input" placeholder="82">
                                    <input class="input" type="text" v-model="user.phone" name="phone" @input="input" placeholder="10-0000-0000">
                                </div>
                            </div>
                        </div>

                        <div role="row">
                            <div role="columnheader">작품민팅 URL</div>
                            <div role="cell">
                                <input class="input" type="text" v-model="user.meta.surveyURL" name="surveyURL" @input="input">
                            </div>
                        </div>
                        <div role="row">
                            <div role="columnheader">홈페이지 URL</div>
                            <div role="cell">
                                <input class="input" type="text" v-model="user.meta.siteURL" name="siteURL" @input="input">
                            </div>
                        </div>
                        <div role="row">
                            <div role="columnheader">인스타그램 URL</div>
                            <div role="cell">
                                <input class="input" type="text" v-model="user.meta.instagramURL" name="instagramURL" @input="input">
                            </div>
                        </div>
                        <div role="row">
                            <div role="columnheader">트위터 URL</div>
                            <div role="cell">
                                <input class="input" type="text" v-model="user.meta.twitterURL" name="twitterURL" @input="input">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- 하단버튼 -->
        <div class="bottom-button">
            <button class="button button--border-primary" @click="cancel()">
                <span>취소하기</span>
            </button>
            <button class="button button--primary" @click="submit()">
                <span>회원가입</span>
            </button>
        </div>
        
		<daum-postcode ref="daum-postcode" @change="({ postcode, address }) => { user.postcode = postcode; user.address1 = address; }"></daum-postcode>
        <kcp-cert ref="kcp-cert" @input="verify"></kcp-cert>
    </div>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import Input from '@/components/client/board/skin/board-normal/input.vue';

export default {
    components: {
        DaumPostcode,
        KcpCert,
        Input
    },
    props: ["value"],
    data(){
        return {
            user: this.$props.value || {}
        }
    },
    methods: {
        input(){
            this.$emit("input", this.user);
        },
        cancel(){
            this.$router.go(-1);
        },

        submit(){
            if(this.validate()) this.$emit("submit", this.user);
        },

        certify(){
            this.$refs["kcp-cert"].auth();
        },

        verify(payload){
            this.user._certification = payload._certification;
            var [phone1, phone2, phone3] = payload.phone?.phoneNumberFormat()?.split("-");
            this.user.phone1 = phone1;
            this.user.phone2 = phone2;
            this.user.phone3 = phone3;
        },

        validate(){
            try{
                if (!this.user.email1 || !this.user.email2) throw new Error("아이디를 입력해주세요.");
                if (!this.user.password) throw new Error("비밀번호를 입력해주세요.");
                if (!this.checkPassword(this.user.password)) return false;
                if (!this.user.passwordConfirm) throw new Error("비밀번호 확인을 입력해주세요.");
                if (this.user.password != this.user.passwordConfirm) throw new Error("비밀번호를 확인해주세요");
                
                switch(this.user.type){
                    case "ko": {
                        if (!this.user.phone1 || !this.user.phone2 || !this.user.phone3){
                            if (!this.user._certification) {
                                this.certify();
                                return false;
                            }
                        }
                        else this.$set(this.user, "phone", `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}`);
                        break;
                    }
                    case "en": {
                        if (!this.user.phone0 || !this.user.phone) throw new Error("핸드폰 번호를 입력해주세요.");
                        else this.$set(this.user, "phone", `+${this.user.phone0}-${this.user.phone}`);
                        break;
                    }
                }
                
                if (!this.user.name) throw new Error("이름을 입력해주세요.");
                this.$emit("input", this.user);

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        checkPassword(password) {
			var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
			var checkEng = /[a-zA-Z]/;
			var checkNum = /[0-9]/;

			if(!checkSpecial.test(password)) throw new Error('비밀번호에 특수문자가 포함되어있어야합니다.');
			if(!checkEng.test(password)) throw new Error('비밀번호에 영문이 포함되어있어야합니다.');
			if(!checkNum.test(password)) throw new Error('비밀번호에 숫자가 포함되어있어야합니다.');

			if(password.length < 8 || 16 < password.length) throw new Error('비밀번호는 8~16자까지입니다.');

			return true;
		},
    },
    watch: {
        "user.email"(){ this.$emit("input", this.user); },
        "user.email1"(){ this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}`: null); },
        "user.email2"(){ this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}`: null); },
    }
}
</script>